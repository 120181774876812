<template>
    <card>
        <body-card style="margin: 0;border: none;">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.details') }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loading">
                        <b-form @submit.prevent="handleSubmit(saveAchievement)" @reset.prevent="reset" autocomplete="off">
                            <div class="p-3">
                                <b-row>
                                    <b-col sm="12">
                                        <b-table-simple small resonsive borderless>
                                            <b-tr>
                                                <b-th width="25%">{{ $t('teaGardenService.tea_garden_name') }}</b-th>
                                                <b-th width="2%">:</b-th>
                                                <b-td>
                                                    {{ getGardenName(formData.garden_id) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.task_name') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ getTaskName(formData.task_id) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGardenConfig.description') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ currentLocale === 'en' ? formData.description_en : formData.description_bn }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('globalTrans.start_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ formData.start_date | dateFormat }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('globalTrans.end_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ formData.end_date | dateFormat }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.working_days') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.working_days) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr v-if="taskUnitId">
                                                <b-th>{{ $t('teaGarden.target') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.target) }} {{ getUnitName(taskUnitId) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr v-if="taskUnitId">
                                                <b-th>{{ $t('teaGarden.total_target') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.working_day_target) }} {{ getUnitName(taskUnitId) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.achievement_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ EngBangNum(formData.achievement.achievement_date) }}
                                                </b-td>
                                            </b-tr>
                                        </b-table-simple>
                                        <b-row>
                                           <b-col xs="12" sm="12" md="4">
                                                <ValidationProvider name="Achievement Date" vid="achievement_date" rules="required">
                                                    <b-form-group
                                                        label-for="from_date"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('teaGarden.achievement_date')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <date-picker
                                                        id="achievement_date"
                                                        v-model="formData.achievement.achievement_date"
                                                        class="form-control"
                                                        :placeholder="$t('globalTrans.select_date')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :class="errors[0] ? 'is-invalid' : ''"
                                                    >
                                                    </date-picker>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <body-card>
                                            <!-- search section start -->
                                            <template v-slot:headerTitle>
                                                <h4 class="card-title">{{ $t('teaGardenConfig.worker_information') + ' ' + $t('globalTrans.search') }}</h4>
                                            </template>
                                            <template v-slot:body>
                                                <b-row>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <b-form-group label-for="worker_type">
                                                            <template v-slot:label>
                                                                {{ $t('teaGardenConfig.worker_type') }}
                                                            </template>
                                                            <b-form-select plain
                                                                           v-model="search.worker_type"
                                                                           :options="customWorkerTypeList"
                                                                           id="worker_type"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <ValidationProvider name="designation" vid="designation_id" rules="">
                                                            <b-form-group slot-scope="{ valid, errors }" label-for="designation_id">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGardenConfig.designation') }}
                                                                </template>
                                                                <b-form-select plain v-model="search.designation_id" :options="designationList" id="designation_id"
                                                                               :state="errors[0] ? false : (valid ? true : null)">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <ValidationProvider name="Gender" vid="gender" rules="">
                                                            <b-form-group slot-scope="{ valid, errors }" label-for="gender">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGardenConfig.gender') }}
                                                                </template>
                                                                <b-form-select plain v-model="search.gender" :options="genderList" id="gender"
                                                                               :state="errors[0] ? false : (valid ? true : null)">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <b-form-group label-for="mobile">
                                                            <template v-slot:label>
                                                                {{  $t('teaGardenConfig.worker_name') }}/{{ $t('teaGardenConfig.pf_id') }}
                                                            </template>
                                                            <b-form-input id="mobile"
                                                                          v-model="search.name_pf"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-button size="sm" variant="primary" class="mt-20" @click.prevent="getFormData">
                                                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                            <!-- search section end -->
                                        </body-card>
                                        <body-card>
                                            <!-- search section start -->
                                            <template v-slot:headerTitle>
                                                <h4 class="card-title">{{ $t('teaGardenConfig.worker_list') }} {{ $t('globalTrans.list') }}</h4>
                                            </template>
                                            <template v-slot:body>
                                                <b-table-simple resonsive bordered class="mt-3 mb-0">
                                                    <b-thead>
                                                        <b-tr>
                                                            <b-th width="7%" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th rowspan="2">{{ $t('teaGardenConfig.worker_name') }}</b-th>
                                                            <b-th rowspan="2">{{ $t('teaGardenConfig.pf_id') }}</b-th>
                                                            <b-th rowspan="2">{{ $t('globalTrans.designation') }}</b-th>
                                                            <b-th rowspan="2">{{ $t('teaGardenConfig.worker_type') }}</b-th>
                                                            <b-th colspan="4" class="text-center">{{ $t('teaGarden.achievement') }}</b-th>
                                                            <b-th width="7%" rowspan="2">{{ $t('teaGarden.difference') }}</b-th>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-th width="10%">{{ $t('teaGarden.morning') }}</b-th>
                                                            <b-th width="10%">{{ $t('teaGarden.afternoon') }}</b-th>
                                                            <b-th width="10%">{{ $t('teaGarden.extra') }}</b-th>
                                                            <b-th width="10%">{{ $t('globalTrans.total') }}</b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <template v-if="formData.workers.length">
                                                            <b-tr v-for="(item, index) in formData.workers" :key="index">
                                                                <b-td>{{ $n(index + 1) }}</b-td>
                                                                <b-td>{{ currentLocale === 'en' ? item.worker.worker_name_en : item.worker.worker_name_bn }}</b-td>
                                                                <b-td>{{ item.worker.pf_id | numberConvert }}</b-td>
                                                                <b-td>{{ getDesignation(item.worker.designation_id) }}</b-td>
                                                                <b-td>{{ getWorkerType(item.worker.worker_type) }}</b-td>
                                                                <b-td>
                                                                    <ValidationProvider name="Morning Achievement" :vid="'morning_achievement_'+index" rules="required">
                                                                        <b-form-group
                                                                          slot-scope="{ valid, errors }"
                                                                          :label-for="'morning_achievement_'+index">
                                                                            <b-form-input
                                                                              :disabled="formData.achievement.workers[index].status === 1 ? true : false"
                                                                              :id="'morning_achievement_'+index"
                                                                              v-model.number="formData.achievement.workers[index].morning_achievement"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                              :min="0"
                                                                              @keypress="isNumber"
                                                                              @input="setDifference(index)"
                                                                            ></b-form-input>
                                                                            <div class="invalid-feedback">
                                                                                {{ errors[0] }}
                                                                            </div>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                </b-td>
                                                                <b-td>
                                                                    <ValidationProvider name="Afternoon Achievement" :vid="'afternoon_achievement_'+index" rules="required">
                                                                        <b-form-group
                                                                          slot-scope="{ valid, errors }"
                                                                          :label-for="'afternoon_achievement_'+index">
                                                                            <b-form-input
                                                                              :disabled="formData.achievement.workers[index].status === 1 ? true : false"
                                                                              :id="'afternoon_achievement_'+index"
                                                                              v-model.number="formData.achievement.workers[index].afternoon_achievement"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                              :min="0"
                                                                              @keypress="isNumber"
                                                                              @input="setDifference(index)"
                                                                            ></b-form-input>
                                                                            <div class="invalid-feedback">
                                                                                {{ errors[0] }}
                                                                            </div>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                </b-td>
                                                                <b-td>
                                                                    <ValidationProvider name="Extra Achievement" :vid="'extra_achievement_'+index" rules="required">
                                                                        <b-form-group
                                                                          slot-scope="{ valid, errors }"
                                                                          :label-for="'extra_achievement_'+index">
                                                                            <b-form-input
                                                                              :disabled="formData.achievement.workers[index].status === 1 ? true : false"
                                                                              :id="'extra_achievement_'+index"
                                                                              v-model.number="formData.achievement.workers[index].extra_achievement"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                              :min="0"
                                                                              @keypress="isNumber"
                                                                              @input="setDifference(index)"
                                                                            ></b-form-input>
                                                                            <div class="invalid-feedback">
                                                                                {{ errors[0] }}
                                                                            </div>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                </b-td>
                                                                <b-td>{{ $n(formData.achievement.workers[index].achievement) }}</b-td>
                                                                <b-td>{{ formData.achievement.workers[index].achievement ? $n(formData.achievement.workers[index].achievement - formData.target) : $n(0) }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                        <template v-else>
                                                            <b-tr>
                                                                <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                    </b-tbody>
                                                </b-table-simple>
                                                <b-row class="mt-3" v-if="!task.is_individual">
                                                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <ValidationProvider name="Total Achievement" vid="total_achievement" rules="required|min_value:0">
                                                            <b-form-group
                                                              slot-scope="{ valid, errors }"
                                                              label-for="total_achievement">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.total_achievement') }}
                                                                </template>
                                                                <b-form-input
                                                                  id="total_achievement"
                                                                  v-model="formData.total_achievement"
                                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                  :min="0"
                                                                  @keypress="isNumber"
                                                                  @input="setTotalDifference"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <ValidationProvider name="Difference" vid="total_diff">
                                                            <b-form-group
                                                              slot-scope="{ valid, errors }"
                                                              label-for="total_diff">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.difference') }}
                                                                </template>
                                                                <b-form-input
                                                                  id="total_diff"
                                                                  v-model="formData.total_diff"
                                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                                  disabled
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </body-card>
                                    </b-col>
                                    <b-col sm="12" class="text-right">
                                        <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                                        <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
            </template>
        </body-card>
    </card>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { taskAssignIndividualAchievement, taskAssignSaveIndividualAchievement } from '../../api/routes'
    import { helpers } from '@/utils/helper-functions'
    // import Pdf from './details-pdf'
    export default {
        name: 'Details',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                formData: {
                    id: 0,
                    total_achievement: '',
                    total_diff: '',
                    workers: [],
                    achievement: {
                        achievement_date: '',
                        workers: []
                    }
                },
                taskUnitId: 0,
                task: {
                    is_individual: 0
                },
                search: {
                    achievement_id: this.$route.query.achievement_id,
                    worker_type: 0,
                    designation_id: 0,
                    gender: 0,
                    mobile: '',
                    name_pf: ''
                },
                workerLoading: false
            }
        },
        created () {
            this.getFormData()
        },
        computed: {
            id () {
                return this.$route.params.id
            },
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            gdnTaskList () {
                return this.$store.state.TeaGardenService.commonObj.gdnTaskList
            },
            designationList: function () {
                return this.$store.state.TeaGardenService.commonObj.masterDesignationList
            },
            genderList: function () {
                return this.$store.state.TeaGardenService.commonObj.gender
            },
            unitList () {
                return this.$store.state.TeaGardenService.commonObj.unitList
            },
            customWorkerTypeList: function () {
                return this.$store.state.TeaGardenService.commonObj.workerType
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            async getFormData () {
                // this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignIndividualAchievement + '/' + this.id, this.search)
                if (result.success) {
                    this.formData = result.data
                    const obj = this.gdnTaskList.find(item => item.value === parseInt(this.formData.task_id))
                    this.task = obj
                    if (obj !== undefined && obj.unit_id) {
                        this.taskUnitId = obj.unit_id
                    }
                }
                // this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            // pdfExport () {
            //     const reportTitle = this.$t('teaGardenPanel.small_tea_garden_registration')
            //     Pdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this)
            // },
            async saveAchievement () {
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                const result = await RestApi.putData(teaGardenServiceBaseUrl, taskAssignSaveIndividualAchievement + '/' + this.id, this.formData.achievement)
                if (result.success) {
                    this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })

                    this.$router.push('/tea-garden-service/garden/task-assign-achievement-individual-list/' + this.id)
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
            isImage (path) {
                return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTaskName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getWorkerType (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.workerType.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getDesignation (id) {
                const obj = this.designationList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getGender (id) {
                const obj = this.genderList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getUnitName (id) {
                const obj = this.unitList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            isNumber (evt) {
                helpers.isNumber(evt)
            },
            setDifference (index) {
                const item = this.formData.achievement.workers[index]
                item.achievement = item.morning_achievement + item.afternoon_achievement + item.extra_achievement
                const diff = this.formData.target - item.achievement
                this.formData.achievement.workers[index].diff = diff
            },
           EngBangNum (n) {
                if (this.$i18n.locale === 'bn') {
                    return n.toString().replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
                } else {
                    return n.toString().replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
                }
            },
            setTotalDifference () {
                const diff = this.formData.working_day_target - this.formData.total_achievement
                this.formData.total_diff = diff
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
